import * as Sentry from "@sentry/browser";
import React from "react";
import { ClinicianApi } from "../../clinician-api";
import { Consult, ConsultVisitStatusEnum } from "../../consult-api";

export interface ConsultCardButtonProps {
    consult: Consult;
    methods: {
        acceptConsult: (toast: React.MutableRefObject<any>) => Promise<boolean>;
        getCTA: (toast: React.MutableRefObject<any>) => any;
    };
    disabled?: boolean;
}

export function create(consult: Consult): ConsultCardButtonProps {
    return {
        consult,

        methods: {
            acceptConsult: async function (toast: React.MutableRefObject<any>) {
                try {
                    const client = new ClinicianApi();
                    await client.consult.consultAcceptUpdate(consult.guid, consult);
                    return true;
                } catch (err) {
                    Sentry.captureException(err);
                    toast.current?.show({ severity: "error", sticky: true, summary: "Uh oh!", detail: err.response?.data?.detail, className: "error-toast" });
                }
                return false;
            },

            getCTA: function (toast: React.MutableRefObject<any>) {
                const open = function () {
                    window.location.assign(`/consult/${consult.guid}`);
                };

                switch (consult.visitStatus) {
                    case ConsultVisitStatusEnum.InProgress:
                    case ConsultVisitStatusEnum.ExternalInProgress:
                    case ConsultVisitStatusEnum.InCall:
                        return {
                            label: "Resume",
                            onClickFn: () => open(),
                        };
                    case ConsultVisitStatusEnum.ReadyToResume:
                        return {
                            label: "Resume",
                            onClickFn: async () => {
                                const res = await this.acceptConsult(toast);
                                if (res) {
                                    open();
                                }
                                return res;
                            },
                        };
                    case ConsultVisitStatusEnum.Scheduled:
                    case ConsultVisitStatusEnum.Waiting:
                        return {
                            label: "View",
                            onClickFn: () => open(),
                        };
                    case ConsultVisitStatusEnum.Assigned:
                    case ConsultVisitStatusEnum.Unassigned:
                        return {
                            label: "Accept",
                            onClickFn: async () => {
                                const res = await this.acceptConsult(toast);
                                if (res) {
                                    open();
                                }
                                return res;
                            },
                        };
                }
            },
        },
    };
}
