import { Bug } from "phosphor-react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import React, { useState } from "react";
import ConsultDebugger from "../layout/ConsultDebugger";

const ProgramCard = ({ program: { description, details, guid, learningMaterialsUrl, clinicalProtocolsUrl, emrUrl }, consult: Consult }: any) => {
    const showButtonRow = clinicalProtocolsUrl || learningMaterialsUrl || emrUrl;
    const consult = Consult;
    const [isDebuggerVisible, setIsDebuggerVisible] = useState(false);
    return (
        <div style={{ width: "552px" }}>
            {description || details?.length > 0 ? (
                <>
                    {description && <div className={"text-sm font-normal program-detail-description"}>{description}</div>}
                    {details && details.length > 0 && (
                        <ul className="program-detail-list">
                            {details.map((key: any, index: any) => (
                                <li key={`${guid}-${index}`}>{key}</li>
                            ))}
                        </ul>
                    )}
                </>
            ) : (
                <div className={"text-sm font-normal program-detail-description"}>No information has been provided. Please contact the MedOps team.</div>
            )}
            <div className="program-detail-button-row">
                {showButtonRow && (
                    <>
                        {clinicalProtocolsUrl && clinicalProtocolsUrl !== "" && (
                            <a href={clinicalProtocolsUrl} target="_blank" rel="noopener noreferrer">
                                <Button className="p-button-sm program-detail-button">Clinical Protocols</Button>
                            </a>
                        )}
                        {learningMaterialsUrl && learningMaterialsUrl !== "" && (
                            <a href={learningMaterialsUrl} target="_blank" rel="noopener noreferrer">
                                <Button className="p-button-sm program-detail-button">Learning Materials</Button>
                            </a>
                        )}
                        {emrUrl && emrUrl !== "" && (
                            <a href={emrUrl} target="_blank" rel="noopener noreferrer">
                                <Button className="p-button-sm program-detail-button">EMR</Button>
                            </a>
                        )}
                    </>
                )}
                <Tooltip target=".debug-button" content="Debug consult" position="top" />
                <div className={"debug-button"} onClick={() => setIsDebuggerVisible(true)}>
                    <Bug size={24} color={"#26aebc"} />
                </div>
            </div>
            <Dialog header="Consult Debug Info" visible={isDebuggerVisible} style={{ maxWidth: "80vw" }} modal onHide={() => setIsDebuggerVisible(false)}>
                <ConsultDebugger consult={consult} />
            </Dialog>
        </div>
    );
};

export default ProgramCard;
